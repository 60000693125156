import styled from "styled-components";

const Title = styled.h1`
  font-family: "Montserrat";
  color: var(--dj-white);
  font-style: bold;
  & span {
    font-style: oblique;
  }
  z-index: 11;
  position: relative;
`;

const TutorBlock = styled.div`
  height: auto;
  border: 3px solid white;
  min-width: 150px;
  max-width: 300px;
  padding: 20px;
  flex-grow: 1;
  & img {
    width: 100%;
  }
  z-index: 6;
`;
const Divider = styled.div`
  border-bottom: 3px solid white;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 20px;
`;

const Name = styled.div`
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 40px;
  margin-bottom: 20px;
`;
const IgTag = styled.a`
  font-size: 15px;
  color: var(--dj-white);
  text-decoration: none;
`;

export function Tutor(props: {
  img: string;
  name: string;
  surname: string;
  ig: string;
}) {
  return (
    <TutorBlock>
      <img src={`/img/tutors/${props.img}`}></img>
      <Divider />
      <Name>
        {props.name} <br /> {props.surname}
      </Name>
      <IgTag href={`https://www.instagram.com/${props.ig}/`}>@{props.ig}</IgTag>
    </TutorBlock>
  );
}
const TutorContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;
export function Tutors() {
  return (
    <>
      <Title>
        I TUTOR DI <span>LADY-J</span>
      </Title>
      <TutorContainer>
        <Tutor img="albi.webp" ig="albiscotti" name="albi" surname="scotti" />
        <Tutor img="alex.webp" ig="alexneridj" name="alex" surname="neri" />

        <Tutor img="tommy.webp" ig="djtommyvee" name="tommy" surname="vee" />
        <Tutor
          img="georgia.webp"
          ig="georgia.mos"
          name="georgia"
          surname="mos"
        />
      </TutorContainer>
    </>
  );
}
