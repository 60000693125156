import styled from "styled-components";
import { TitleRigth } from "./sharedComponents";
const Container = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  z-index: 5;
  & > ${TitleRigth} {
    align-self: flex-end;
    margin-right: 0;
  }
  i {
    font-weight: 150;
  }
  & > p {
    text-align: right;
    max-width: 800px;
    align-self: flex-end;
    z-index: 5;
    b {
      color: black;
    }
    @media screen and (max-width: 800px) {
      text-align: center;
      align-self: center;
    }
  }
`;

export function DiscoverTour() {
  return (
    <Container>
      <TitleRigth>
        <b> i nostri Live Show e DJ Set</b> <br />
        <i>sarà un’estate incredibile</i>
      </TitleRigth>
      <p>
        <b> 4 tappe tra Giugno e Luglio divise su 2 serate:</b> <br />
        il <b>venerdì</b>, esibizione nel pre-show del radio 105 SUMMER
        FESTIVAL, dove le DJ troveranno un pubblico carico di energia. Il
        <b>sabato</b>, un DJ set al tramonto per celebrare insieme una piacevole
        serata estiva accompagnati da good vibes! Una volta concluso il
        percorso, ci troveremo insieme alla giuria tecnica in un top beach club
        d’Italia per mettere alla rpova le DJ in una <b>finale</b> piena di
        sorprese!
      </p>
    </Container>
  );
}
