import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import { DjImg } from "../components/djImg";
import { Button } from "../components";
import { Artists_Enum } from "../generated/graphql";
import { djDetails } from "./djDetails";
import { useAppContext } from "../context/context";
import { Link } from "react-router-dom";
import { TappableHearth } from "../components/tappableHearh";

const DjContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);

  grid-row-gap: 20px;
  max-width: 300px;
  ${DjImg} {
    grid-area: 1 / 1 / 2 / 4;
    justify-self: center;
  }

  ${Button} {
    grid-area: 4 / 1 / 5 / 4;
    justify-self: center;
  }
`;

const LikeHearth = styled(TappableHearth)`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 80px;
  grid-area: 3 / 1 / 4/ 2;
`;

const InstaIcon = styled.a`
  grid-area: 2 / 1 / 3 / 3;
  justify-self: center;
  font-size: 50px;
  color: black;
`;

const SoundCloud = styled.a`
  grid-area: 2 / 2 / 3 / 4;
  justify-self: center;
  font-size: 50px;
  color: black;
`;

const LikeCounter = styled.div`
  font-weight: 600;

  font-size: 30px;
  color: var(--dj-white);
  grid-area: 3 / 2 / 4/ 4;
  p {
    color: black;
    font-size: 10px;
    font-weight: 400;
    margin-top: 0px;
  }
`;

export function DjBox(props: { dj: Artists_Enum }) {
  const { rankingsMap, vote, isAuthenticated, usedVotes } = useAppContext();
  const dj = djDetails.get(props.dj);
  if (!dj) return null;

  const votes = rankingsMap.get(props.dj);
  return (
    <>
      <DjContainer>
        <DjImg size="200px" backgroundimage={dj.img} content={dj.name}></DjImg>
        <SoundCloud href={dj.soundcloud}>
          <FontAwesomeIcon icon={faSoundcloud}></FontAwesomeIcon>
        </SoundCloud>
        <InstaIcon href={dj.instagram}>
          <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
        </InstaIcon>

        <LikeCounter>{votes} LIKE</LikeCounter>
      </DjContainer>
    </>
  );
}
