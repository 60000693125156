import styled from "styled-components";

export const ShadeContainer = styled.div`
  background: linear-gradient(
    90deg,
    rgba(210, 81, 163, 1) 0%,
    rgba(230, 143, 83, 1) 100%
  );

  overflow-x: hidden;
  position: relative;
  padding-bottom: 50px;
  @media screen and (max-width: 800px) {
    width: 110vw;
  }
  min-height: 100vh;
`;
