import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MenuIcon = styled.div`
  margin-right: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  filter: brightness(0) invert(1);

  background-size: contain;
  background-repeat: no-repeat;
  @media screen and (max-width: 800px) {
    height: 50px;
    width: 50px;
  }
`;
const AccediIcon = styled(MenuIcon)`
  background-image: url("/img/accedi.webp");
`;
const EsciIcon = styled(MenuIcon)`
  background-image: url("/img/esci.webp");
`;
const VotaIcon = styled(MenuIcon)`
  background-image: url("/img/vota.webp");
`;

const TopBarContainer = styled.div`
  height: 80px;
  width: 100%;
  align-items: center;
  margin-bottom: -80px;
  z-index: 10;
  position: absolute;
  margin-top: 20px;
  background-position: 2%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 800px) {
    height: 50px;
    margin-bottom: -50px;
    margin-top: 20px;
    ${MenuIcon} {
      font-size: 50px;
    }
  }
`;

const Logo = styled.img`
  height: 100%;
  width: auto;
  margin-right: auto;
  margin-left: 20px;
  cursor: pointer;
`;

export function TopBar() {
  const { isAuthenticated, loginWithPopup, logout, getAccessTokenSilently } =
    useAuth0();

  const navigate = useNavigate();
  const goHome = () => navigate("/");

  return (
    <TopBarContainer>
      <Logo src="/img/landing/logo.webp" onClick={goHome} />
      {isAuthenticated ? (
        <>
          <Link to="/vote">
            <VotaIcon />
          </Link>
          <span
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
          >
            <EsciIcon />
          </span>
        </>
      ) : (
        <span onClick={() => loginWithPopup()}>
          <AccediIcon />
        </span>
      )}
    </TopBarContainer>
  );
}
