import styled from "styled-components";

const InstrContainer = styled.div`
  width: 250px;
  height: 500px;
  background-color: #383838;
  padding: 95px 45px;
  li {
    margin-top: 10px;
  }

  h1 {
    text-align: center;
    margin: 0px;
  }
  p {
    text-align: right;
  }
  button {
    color: var(--dj-white);
    background-color: transparent;
    border: 2px solid white;
    padding: 20px 30px;
    font-size: 20px;

    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 105vh;
    padding: 20px 10px;
  }
`;

export function Instructions(props: { close: () => void }) {
  return (
    <>
      <InstrContainer>
        <h1>ISTRUZIONI</h1>
        <ul>
          <li> Registrati o accedi per abilitare il pulsante di voto.</li>
          <li>
            Per votare ti basterà cliccare sul cuore vicino alla foto profilo
            della tua DJ preferita!
          </li>
          <li>I like sono cumulativi, la classifica si aggiorna live.</li>
          <li>
            Potrai votare in qualsiasi momento e distribuire i voti su più DJ:
            ogni lunedì si resetta il conteggio dei like e potrai distribuirli
            nuovamente durante la settimana successiva!
          </li>
          <li>
            I voti degli utenti sul sito si aggiungeranno al giudizio della
            giuria tecnica durante la finale.
          </li>
          <li>
            Per qualsiasi dubbio, non esitare a contattarci attraverso il form
            in home page.
          </li>
        </ul>
        <button onClick={props.close}>HO CAPITO</button>
      </InstrContainer>
    </>
  );
}
