import { createContext, useContext } from "react";
import {
  Artists_Enum,
  useUserDetailsQuery,
  useVoteForMutation,
  useVotingStatusQuery,
  UserDetailsQuery,
  InputMaybe,
  Users_Set_Input,
} from "../generated/graphql";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUpdateUserMutation } from "../generated/graphql";
type UserDetail = UserDetailsQuery["users_by_pk"];

export const AppContext = createContext({
  usedVotes: 0,
  isAuthenticated: false,
  refresh: () => {},
  rankingsMap: new Map<Artists_Enum, number>(),
  vote: (artist: Artists_Enum) => {},
  userDetail: {} as UserDetail,
  userId: undefined as undefined | string,
});

export function AppProvider(props: { children: React.ReactNode }) {
  const [query, refreshRaw] = useVotingStatusQuery();

  const { isAuthenticated, user } = useAuth0();
  const [userDetails, refreshUser] = useUserDetailsQuery({
    variables: { user_id: user?.sub || "" },
  });
  const [_, voteFor] = useVoteForMutation();
  const notifyMaxLikes = () =>
    toast.error(
      "Hai finito i tuoi like!\n Si aggiorneranno settimana prossima"
    );

  const usedVotes = query.data?.user_weekly_votes[0]?.weekly_votes;

  function vote(artist: Artists_Enum) {
    if (usedVotes >= 50) {
      notifyMaxLikes();
      return;
    }
    voteFor({ voted_for: artist }).then(() => {
      refresh();
    });
  }

  const rankings = new Map<Artists_Enum, number>();
  query.data?.artist_votes_count.forEach((dj) => {
    rankings.set(dj.artist_id as Artists_Enum, dj.votes_count);
  });

  const refresh = () => {
    refreshRaw({ requestPolicy: "network-only" });
  };
  return (
    <AppContext.Provider
      value={{
        usedVotes,
        isAuthenticated,
        refresh,
        rankingsMap: rankings,
        vote,

        userDetail: userDetails.data?.users_by_pk || undefined,
        userId: user?.sub,
      }}
    >
      <ToastContainer />

      {props.children}
    </AppContext.Provider>
  );
}

export function useUpdateUser() {
  const context = useContext(AppContext);
  const [_, updateUserMut] = useUpdateUserMutation();
  function updateAvatar(avatar: string) {
    if (!context.userId) return;
    updateUserMut({ user_id: context.userId, _set: { avatar } }).then(
      context.refresh
    );
  }
  function registerUserDetails(data: InputMaybe<Users_Set_Input>) {
    if (!context.userId) return;
    updateUserMut({
      user_id: context.userId,
      _set: { ...data, conset_capture: true },
    }).then(context.refresh);
  }
  return { updateAvatar, registerUserDetails };
}

export function useAppContext() {
  const context = useContext(AppContext);

  return context;
}
