import styled from "styled-components";
import { DjImg } from "../components/djImg";
import { Artists_Enum } from "../generated/graphql";
import { useAppContext } from "../context/context";
import { Link } from "react-router-dom";
import { djDetails } from "../djPage/djDetails";
import { TappableHearth } from "../components/tappableHearh";
export const DjBoxContainer = styled.div`
  border: 2px solid white;
  height: 200px;
  width: 322px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
`;

const LikeCounter = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
`;
const Hearth = styled(TappableHearth)`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 60px;
`;
const LikesNr = styled.div`
  font-size: 20px;
  font-weight: 600;
`;
const VoteIndicator = styled.div`
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;

  ${LikeCounter}:hover & {
    opacity: 1;
  }
`;
export function DjBox(props: { name: Artists_Enum }) {
  const { rankingsMap, vote, isAuthenticated } = useAppContext();
  const votes = rankingsMap.get(props.name);
  const dj = djDetails.get(props.name);
  if (!dj) return null;
  return (
    <DjBoxContainer>
      <Link to={"/dj/" + props.name}>
        <DjImg backgroundimage={dj?.img} content={dj.name} size="130px"></DjImg>
      </Link>
      <LikeCounter>
        <Hearth onClick={() => vote(props.name)} />
        <VoteIndicator>
          {isAuthenticated ? (
            "Clicca sul cuore per votare"
          ) : (
            <Link to="/login">Accedi o registrati per votare</Link>
          )}
        </VoteIndicator>
        <LikesNr>{votes} LIKE</LikesNr>
      </LikeCounter>
    </DjBoxContainer>
  );
}
