import styled from "styled-components";

export const TappableHearth = styled.div`
  background-image: url("/img/cuore.webp");

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;
