import styled from "styled-components";

export const TitleRigth = styled.div`
  font-family: "Montserrat";
  color: var(--dj-white);
  font-style: normal;
  font-weight: 500;
  text-align: right;

  position: relative;
  z-index: 5;
  font-size: 40px;
  max-width: 600px;
  float: right;

  @media screen and (max-width: 800px) {
    text-align: center;
    float: none;
    margin-right: 0px;
    max-width: 100%;
    width: 100%;
  }
`;

export const TitleLeft = styled.div`
  font-family: "Montserrat";
  color: var(--dj-white);
  font-style: normal;
  font-weight: 500;

  position: relative;
  z-index: 5;
  font-size: 40px;
  max-width: 600px;
`;
