import styled from "styled-components";
import { ImgBanner } from "./components";

import { DiscoverTour } from "./discoverTour";
import { Tutors } from "./tutors";
import { Locations } from "./locations";
import { KeepIntTouch } from "./keepInTouch";
import { Sponsors } from "./sponsors";
import { Footer } from "./footer";
import { KnowBetter } from "./knowBetter/knowBetter";

import { TeamBuilding } from "./teamBuildingDay";
import { ShadeContainer } from "./components/shadeContainer";
import { Introduction } from "./Introduction/introduction";
import { NoFinishHere } from "./noFinishHere";
import { YoutubeEmbed } from "./components/embedYoutube";

const ContainerMargin = styled.div`
  padding: 50px;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`;

const Decoration = styled.div<{
  backgroundimage: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  position: absolute;
  z-index: 3;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  width: 70vh;

  height: 70vh;
  background-size: contain;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  @media screen and (max-width: 900px) {
    width: 50vh;
    height: 50vh;
  }
`;

export function Selection() {
  return (
    <ShadeContainer>
      <ContainerMargin>
        <Introduction />
        <KnowBetter></KnowBetter>
        <Tutors />
        <TeamBuilding></TeamBuilding>
        <Decoration
          backgroundimage="/decoration.webp"
          top="-229px"
          right="10px"
        />
        <Decoration backgroundimage="/decorations/1.webp" top="250px" />
        <Decoration
          backgroundimage="/decorations/2.webp"
          top="1050px"
          right="5px"
        />
        <Decoration
          backgroundimage="/decorations/3.webp"
          bottom="2350px"
          right="3px"
        />

        <DiscoverTour />
      </ContainerMargin>
      <ImgBanner
        textColor="black"
        title="il palco di RADIO 105"
        description="105 SUMMER FESTIVAL, i 4 grandi eventi dell'estate dove si esibiranno le nostre DJ e i loro Tutor, di fronte a decine di migliaia  di persone a cui chiederemo di votare sulla nostra piattaforma lady-j.it."
        imageUrl="/img/radio105.webp"
      />
      <ContainerMargin>
        <Locations />
      </ContainerMargin>
      <ImgBanner
        textColor="black"
        title="NIKITA VOGUEL É LA VINCITRICE DELLA PRIMA EDIZIONE DEL LADY-J TALENT,

LA NOSTRA REGINA DELLA TECHNO HA CONQUISTATO IL PODIO"
        description={
          <>
            Guarda il video qui sotto per scoprire cos’è successo durante la
            puntata finale del nostro format dedicato alle DJ emergenti!
            <br />
            <br />
            In questo episodio conclusivo, le nostre DJ si sono esibite presso
            il Samsara Beach Club di Riccione di fronte a una giuria tecnica il
            cui giudizio si è unito al gradimento del pubblico espresso in
            questi mesi tramite le votazioni su questo sito.
            <br />
            <br />
            Siamo lieti di annunciare che Nikita Voguel ha vinto la prima
            edizione, distinguendosi per la sua tecnica e presenza scenica.
            <br />
            <br />
            Nikita, con il suo mix di darkwave, electro e techno, ha saputo
            conquistare sia la giuria che il pubblico!
          </>
        }
        imageUrl="/img/samsara.webp"
      />
      <YoutubeEmbed embedId="KIsftEliqDI"></YoutubeEmbed>

      <ContainerMargin>
        <NoFinishHere></NoFinishHere>
        <KeepIntTouch />
        <Sponsors />
      </ContainerMargin>
      <Footer />
    </ShadeContainer>
  );
}
