export const YoutubeEmbed = (props: { embedId: string }) => (
  <iframe
    width="100%"
    src={`https://www.youtube.com/embed/${props.embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
    style={{ aspectRatio: "16/9" }}
  />
);
