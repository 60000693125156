import styled from "styled-components";
import { YoutubeEmbed } from "../components/embedYoutube";

const Title = styled.h1`
  font-weight: 500;
`;

const VideoGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export function VideoSection(props: { title: string; videoIds: string[] }) {
  return (
    <>
      <Title>{props.title}</Title>

      <VideoGrid>
        {props.videoIds.map((videoId) => (
          <YoutubeEmbed embedId={videoId}></YoutubeEmbed>
        ))}
      </VideoGrid>
    </>
  );
}
