import styled from "styled-components";
import { YoutubeEmbed } from "../components/embedYoutube";

const Video = styled.div`
  width: 100%;
  flex: 1;

  min-width: min(80vw, 500px);
  aspect-ratio: 16 / 9;
  z-index: 5;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  z-index: 5;
  position: relative;
  gap: 30px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  padding-top: 120px;
  @media screen and (max-width: 900px) {
    padding-top: 0;
  }
`;
const RighColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  flex: 1;
`;

export function Introduction() {
  return (
    <Container>
      <LeftColumn>
        <Video>
          <YoutubeEmbed embedId="PF2Eoa9gQZI"></YoutubeEmbed>
        </Video>
      </LeftColumn>
      <RighColumn>
        <h1> Un talent pensato per supportare le DJ di domani</h1>
        <p>
          {" "}
          Crediamo nella bellezza della musica e vogliamo supportare le donne
          che cercano la loro strada in questo settore. COME? affiancandole a
          professionisti che potranno guidarle verso una carriera di successo.
        </p>
      </RighColumn>
    </Container>
  );
}
