import { useAppContext, useUpdateUser } from "../context/context";
import { UserName, LikeCounter, LikeHearth } from "./votingPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const UserImg = styled.div<{ bgImage?: string }>`
  background-image: url(${(props) => props.bgImage});
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 25px;
  position: relative;
`;
const PenContainer = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
  left: 0;
  width: 17px;
  margin-left: auto;
  margin-right: auto;
  color: black;
  visibility: hidden;
  label {
    cursor: pointer;
  }
`;

const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.529);
  border-radius: 5px;
  width: 250px;
  input {
    visibility: hidden;
  }
  &:hover ${PenContainer} {
    visibility: visible;
  }
`;

export function UserDetails() {
  const { usedVotes, userDetail } = useAppContext();
  const { updateAvatar } = useUpdateUser();
  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 500;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > MAX_SIZE) {
              height *= MAX_SIZE / width;
              width = MAX_SIZE;
            }
          } else {
            if (height > MAX_SIZE) {
              width *= MAX_SIZE / height;
              height = MAX_SIZE;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx?.drawImage(image, 0, 0, width, height);

          const resizedImage = canvas.toDataURL("image/jpeg");
          updateAvatar(resizedImage);
        };
      };
      reader.readAsDataURL(file);
    }
  }
  return (
    <>
      <UserBox>
        <UserImg bgImage={userDetail?.avatar || undefined}>
          <PenContainer>
            <input type="file" id="actual-btn" onChange={handleFileChange} />

            <label htmlFor="actual-btn">
              <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
            </label>
          </PenContainer>
        </UserImg>

        <UserName>{userDetail?.user_name}</UserName>
        <LikeCounter>
          <LikeHearth />
          <div>
            <b> {usedVotes || 0}/50 </b>
            <br />I TUOI LIKE
          </div>
        </LikeCounter>
      </UserBox>
    </>
  );
}
