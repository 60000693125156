import styled from "styled-components";

export const Button = styled.button`
  background-color: transparent;
  font-family: "Montserrat";
  color: var(--dj-white);
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
`;
export const Container = styled.div<{
  whiteBg?: boolean;
}>`
  padding: 50px;
  z-index: 5;
  margin-top: 50px;
  @media screen and (max-width: 900px) {
    padding: 20px;
  }
  & h1 {
    text-align: center;
  }
  & p {
    text-align: center;
    max-width: 800px;
    font-size: 20px;
    font-weight: 500;
  }

  background-color: ${(props) => (props.whiteBg ? "#ffffffb5" : "transparent")};
`;

export const ImgBannerContainer = styled.div<{
  imageUrl: string;
  textColor: string;
}>`
  background: url(${(props) => props.imageUrl}), #000000;
  width: 100%;
  aspect-ratio: 16/9;
  background-size: cover;

  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.textColor};

  @media screen and (max-width: 900px) {
    aspect-ratio: 1/1;
  }
`;
export const OpacityContainer = styled.div`
  background-color: #0000008f;

  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export interface ImgBannerProps {
  title: string;
  description: string | JSX.Element;
  imageUrl: string;
  textColor: string; // Add the textColor prop
  whiteBg?: boolean;
}

export function ImgBanner({
  title,
  description,
  imageUrl,
  textColor,
  whiteBg,
}: ImgBannerProps) {
  return (
    <ImgBannerContainer imageUrl={imageUrl} textColor={textColor}>
      <Container whiteBg>
        <h1>{title}</h1>
        <p>{description}</p>
      </Container>
    </ImgBannerContainer>
  );
}

export const TextArea = styled.textarea`
  width: 100%;
  background-color: rgb(68 68 68 / 50%);
  height: 150px;
  border: none;
  color: var(--dj-white);
  font-family: "Montserrat";
  &::placeholder {
    color: black;
  }
  &:focus {
    outline: none !important;
  }
`;
