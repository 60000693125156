import styled from "styled-components";

export const DjImg = styled.div<{
  backgroundimage?: string;
  content?: string;
  size: string;
}>`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  &::after {
    content: "${(props) => props.content}";
    display: block;
    position: relative;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 40%;
    left: 65%;
    font-size: xx-large;
    font-weight: 600;
  }
`;
