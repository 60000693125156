import styled from "styled-components";
import { ShadeContainer } from "./components/shadeContainer";

const Container = styled(ShadeContainer)`
  display: flex;

  gap: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 250px;
  }
  overflow: hidden;
  text-align: center;
`;
export function VerifyEmail() {
  return (
    <Container>
      <img src="img/landing/logo.webp"></img>
      Grazie per esserti registrata/o, controlla la tua email per confermare la
      tua registrazione.
    </Container>
  );
}
