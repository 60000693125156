import styled from "styled-components";
import { SponsorContainer, SponsorImg } from "../sponsors";
const Title = styled.h1`
  text-align: center;
  font-weight: 100;
  margin-top: 50px;
  z-index: 5;
  position: relative;
`;
export function Logos() {
  return (
    <>
      <Title>POWERED BY</Title>
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/shiseido.webp" />
        <SponsorImg src="/img/sponsors/marionnaud.webp" />
        <SponsorImg src="/img/sponsors/iceberg.webp" />
        <SponsorImg src="/img/sponsors/denon.webp" />
        <SponsorImg src="/img/sponsors/techinics.webp" />
      </SponsorContainer>
    </>
  );
}
