import React, { useEffect, useMemo } from "react";
import { Client, cacheExchange, fetchExchange, Provider } from "urql";

import { LandingPage } from "./landing_page";
import { Selection } from "./selection";
import { TopBar } from "./components/topbar";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ShadeContainer } from "./components/shadeContainer";
import { DjPage } from "./djPage/djPage";
import { useAuth0 } from "@auth0/auth0-react";
import { AppProvider } from "./context/context";
import { VotingPage } from "./vote/votingPage";
import ScrollToTop from "./components/scrollTop";
import { LoginPage } from "./loginpage/loginPage";
import { VerifyEmail } from "./verifyemail";
import { LoginVerified } from "./loginpage/loginVerified";
import { GetData } from "./getData/getData";
import ReactGA from "react-ga4";
import LogRocket from "logrocket";
LogRocket.init("msgjax/ladyj");

ReactGA.initialize("G-JJTWXEVCS7");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <TopBar />
        <LandingPage />
        <Selection />
      </>
    ),
  },
  {
    path: "/dj/:id",
    element: (
      <>
        <ScrollToTop />

        <TopBar />
        <ShadeContainer>
          <DjPage />
        </ShadeContainer>
      </>
    ),
  },
  {
    path: "/vote",
    element: (
      <>
        <ScrollToTop />
        <TopBar />

        <ShadeContainer>
          <VotingPage />
        </ShadeContainer>
      </>
    ),
  },
  {
    path: "/login",
    element: (
      <>
        <ScrollToTop />
        <TopBar />
        <ShadeContainer>
          <LoginPage />
        </ShadeContainer>
      </>
    ),
  },
  {
    path: "/loginverified",
    element: (
      <>
        <ScrollToTop />
        <TopBar />
        <ShadeContainer>
          <LoginVerified />
        </ShadeContainer>
      </>
    ),
  },

  {
    path: "/verifyemail",
    element: (
      <>
        <VerifyEmail />
      </>
    ),
  },
]);

function App() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = React.useState<string | null>(null);
  const client = useMemo(
    () =>
      new Client({
        url: process.env.REACT_APP_GRAPHQL_ENDPOINT || "",
        exchanges: [cacheExchange, fetchExchange],
        fetchOptions: () => {
          if (token) {
            return {
              headers: { authorization: token ? `Bearer ${token}` : "" },
            };
          }
          return {};
        },
      }),
    [token]
  );
  useEffect(() => {
    isAuthenticated &&
      getAccessTokenSilently().then((token) => {
        setToken(token);
      });
  }, [getAccessTokenSilently, isAuthenticated]);
  return (
    <div className="App">
      <Provider value={client}>
        <AppProvider>
          <GetData />
          <RouterProvider router={router} />
        </AppProvider>
      </Provider>
    </div>
  );
}

export default App;
