import styled from "styled-components";
import { TitleRigth } from "./sharedComponents";
import { TextArea, Button } from "./components";
import { useForm, ValidationError } from "@formspree/react";

const ContactForm = styled.form`
  border: 1px solid white;
  padding: 10px;
  margin-top: 60px;
  width: 100%;
  max-width: 1000px;
  z-index: 5;
`;

const BottomContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 5;
`;

export const InputEmail = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 4px solid white;
  width: 100%;
  min-width: 200px;
  margin-right: 30px;
  max-width: 800px;
  z-index: 5;
  outline: none;
  color: var(--dj-white);
  margin-top: 20px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 5;
`;
export function KeepIntTouch() {
  const [state, handleSubmit] = useForm("xkndeqqw");
  if (state.succeeded) {
    return <p>Grazie per averci contattato!</p>;
  }

  return (
    <>
      <Container>
        <TitleRigth>ti siamo entrati nel cuore?</TitleRigth>

        <ContactForm onSubmit={handleSubmit}>
          <h1>keep in touch</h1>
          <p>
            per scoprire di più o entrare a far parte del progetto, scrivici due
            righe
          </p>
          <TextArea
            id="message"
            name="message"
            placeholder="inizia a scrivere qui..."
          ></TextArea>

          <BottomContainer>
            <div>
              <label htmlFor="email">la tua e-mail:</label>
              <br />
              <InputEmail id="email" type="email" name="email"></InputEmail>
            </div>

            <div>
              <Button type="submit" disabled={state.submitting}>
                Invia
              </Button>
            </div>
          </BottomContainer>
        </ContactForm>
      </Container>
    </>
  );
}
