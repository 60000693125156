import styled from "styled-components";

const Title = styled.h1`
  text-align: center;
  font-weight: 100;
  margin-top: 50px;
  z-index: 5;
  position: relative;
`;
export const SponsorContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  z-index: 5;
`;

export const SponsorImg = styled.img<{ big?: boolean }>`
  max-width: ${(props) => (props.big ? "400px" : "200px")};
  max-height: ${(props) => (props.big ? "200px" : "100px")};
  width: auto;
  height: auto;
  z-index: 5;
  position: relative;
  object-fit: contain;
  @media screen and (max-width: 900px) {
    height: ${(props) => (props.big ? "120px" : "60px")};
    max-width: 100%;
  }
`;
export function Sponsors() {
  return (
    <>
      <Title>PRODUCED BY</Title>
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/mm.webp" big />
      </SponsorContainer>
      <Title>POWERED BY</Title>
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/shiseido.webp" big />
        <SponsorImg src="/img/sponsors/marionnaud.webp" big />
      </SponsorContainer>
      <br />
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/ergovis.webp" big />
      </SponsorContainer>
      <br />
      <br />
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/iceberg.webp" big />
      </SponsorContainer>
      <Title>TECHNICAL PARTNERS</Title>
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/denon.webp" big />
        <SponsorImg src="/img/sponsors/techinics.webp" big />
      </SponsorContainer>
      <Title>SUPPORTED BY</Title>
      <SponsorContainer>
        <SponsorImg src="/img/sponsors/igr.webp" />
        <SponsorImg src="/img/sponsors/bespoke.webp" />
        <SponsorImg src="/img/sponsors/canaletv.webp" />
        <SponsorImg src="/img/sponsors/digitalsoul.webp" />
        <SponsorImg src="/img/sponsors/studiopiu.webp" />
      </SponsorContainer>
    </>
  );
}
