import { Link } from "react-router-dom";
import { DjImg } from "../components/djImg";
import { useAppContext } from "../context/context";
import { djDetails } from "../djPage/djDetails";
import styled from "styled-components";
import { TappableHearth } from "../components/tappableHearh";

const FlexContainer = styled.div`
  display: flex;

  justify-content: center;

  p {
    text-align: center;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 30px;
  }
`;

const Container = styled.div`
  padding: 30px;
  p {
    text-align: center;
  }
`;

const ImgContainer = styled.div`
  width: 250px;
  @media screen and (max-width: 1000px) {
    width: auto;
  }
`;

const LikeHearth = styled(TappableHearth)`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;

  margin-right: 10px;
`;

const LikeContainer = styled.div`
  display: flex;
  margin-top: 20px;
  line-height: 60px;
  font-size: 25px;
`;

export function DjList() {
  const { rankingsMap, vote, usedVotes } = useAppContext();
  const djs = Array.from(rankingsMap.entries());

  return (
    <>
      <FlexContainer>
        {djs.map(([dj, votes], index) => (
          <ImgContainer>
            <Link to={"/dj/" + dj}>
              <DjImg
                backgroundimage={djDetails.get(dj)?.img}
                content={djDetails.get(dj)?.name}
                size="130px"
              ></DjImg>
            </Link>
            <LikeContainer>
              <LikeHearth onClick={() => vote(dj)} />
              <div>{votes} LIKE</div>
            </LikeContainer>
          </ImgContainer>
        ))}
      </FlexContainer>
      <Container>
        <p>
          Hai usato <b>{usedVotes}</b> dei tuoi <b>50</b> VOTI SETTIMANALI!{" "}
          <br />
          Clicca sul cuore vicino all’immagine profilo per votare la tua DJ
          preferita
        </p>
      </Container>
    </>
  );
}
