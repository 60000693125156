import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Container = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 30px;
  row-gap: 20px;

  @media screen and (max-width: 800px) {
    column-gap: 15px;
    row-gap: 10px;
  }
  b:nth-child(1) {
    text-align: right;
  }
  p:nth-child(3) {
    text-align: right;
  }
  p {
    margin: 0;
  }
  i {
    font-weight: 150;
  }
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: var(--dj-white);
    top: 0;
    left: 50%;
  }
`;

const IconContainer = styled.div`
  font-size: 60px;

  display: flex;
  gap: 20px;
  align-items: center;
  a {
    color: var(--dj-white);
  }
`;
export function Footer() {
  return (
    <Container>
      <b>MATCH MUSIC GROUP</b>
      <b>SEGUICI SU</b>
      <p>
        eventi@matchmusic.it <br />© 2024 - Match Music S.R.L.
        <br />
        P.IVA 03080200235 REA
        <br />
        MI1700062 Cap. Soc. 10.000,00 € i.v. - Milano
        <br />
        <i> PRIVACY POLICY | COOKIE POLICY</i>
      </p>

      <IconContainer>
        <a href="https://www.instagram.com/ladyj_talent/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.youtube.com/@LADYJ_talent">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </IconContainer>
    </Container>
  );
}
