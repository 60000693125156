import styled from "styled-components";
import { OpacityContainer } from "../components";
import { useAppContext, useUpdateUser } from "../context/context";
import { FieldValues, useForm } from "react-hook-form";

const DataForm = styled.form`
  width: 100%;
  max-width: 500px;
  height: 90vh;
  background-color: black;
  overflow-y: scroll;
  display: flex;
  overscroll-behavior: contain;

  padding: 20px;
  padding-bottom: 50px;

  label {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 300;
    span {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  input {
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    font-size: 20px;
    margin-bottom: 30px;
    max-width: 500px;
  }
  select {
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    font-size: 20px;
    select:focus {
      background-color: black;
    }
    &:focus > option {
      background-color: black;
    }
  }
  i {
    color: #8280f3;
  }
  button {
    color: var(--dj-white);
    background-color: transparent;
    border: 2px solid white;
    padding: 10px 30px;
    font-size: 20px;

    cursor: pointer;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 800px) {
    & > div {
      max-width: 500px;
    }
    height: 100%;
  }
`;
export function GetData() {
  const { userDetail } = useAppContext();

  const { registerUserDetails } = useUpdateUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function sendData(data: FieldValues) {
    registerUserDetails(data);
  }

  return (
    <>
      {userDetail?.conset_capture === false && (
        <OpacityContainer>
          <DataForm onSubmit={handleSubmit((data) => sendData(data))}>
            <div>
              <h2>Per favore completa la registrazione</h2>
              <label htmlFor="user_name">Inserisci il tuo nome *</label>
              <br />
              <input id="user_name" required {...register("user_name")}></input>
              <br />
              <label htmlFor="age">Età</label>
              <br />
              <select id="age" {...register("age")}>
                <option value="NO-DECLARE" disabled selected>
                  Seleziona
                </option>
                <option value="18-25">18-25</option>
                <option value="26-35">26-35</option>
                <option value="35-50">35-50</option>
                <option value="50+">50+</option>
              </select>
              <br />
              <label htmlFor="gender">SESSO</label>
              <br />
              <select id="gender" {...register("gender")}>
                <option value="NO-DECLARE" disabled selected>
                  Seleziona
                </option>
                <option value="LADY">LADY</option>
                <option value="MISTER">MISTER</option>

                <option value="NO-DECLARE">PREFERISCO NON DICHIARARE</option>
              </select>
              <h2>PRIVACY</h2>
              <p>
                “L’interessato è consapevole del fatto che l’Agenzia MATCH MUSIC
                SRL è l’unico soggetto responsabile del trattamento dei propri
                dati personali dei soggetti candidati e del pubblico votante, ai
                sensi dell’articolo 4, punti 2) e 7) e 28, del Regolamento
                Europeo n. 679 del 27 Aprile 2016 (“GDPR”).  SHISEIDO svolge,
                invece, il solo ruolo di sponsor e partner commerciale
                dell’evento LADY-J, non essendo in alcun modo responsabile del
                trattamento dei dati, non avendo interesse agli stessi, al cui
                contenuto non ha comunque accesso”.
                <br />
                <br />
                <i>
                  cliccando “invia” dichiari di aver letto e compreso le
                  condizioni
                </i>
              </p>
              <input type="checkbox" id="privacy" required />
              <label htmlFor="privacy">
                <span>Accetto le condizioni sulla privacy *</span>
              </label>
              <br />
              <input
                type="checkbox"
                id="consent_news"
                {...register("consent_news")}
              />
              <label htmlFor="newsletter">
                <span>Accetto di ricevere newsletter da Match Music S.R.L</span>
              </label>
              <br />
              <input
                type="checkbox"
                id="consent_third_news"
                {...register("consent_third_news")}
              />
              <label htmlFor="consent_third_news">
                <span>
                  Accetto di ricevere comunicazioni da partner di Match Music
                  S.R.L
                </span>
              </label>
              <br />
              <button type="submit">Invia</button>
            </div>
          </DataForm>
        </OpacityContainer>
      )}
    </>
  );
}
