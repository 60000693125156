import { Artists_Enum } from "../generated/graphql";

export type DjDetails = {
  name: string;

  img: string;
  description: string;
  instagram: string;
  soundcloud: string;
  videoMeetDj?: string[];
};

export const djDetails = new Map<Artists_Enum, DjDetails>();
djDetails.set(Artists_Enum.Dizzy, {
  name: "@dizzy",
  img: "/img/djpics/DIZZY.webp",
  description: `Dizzy, classe ‘98, è un’artista fiorentina trapiantata a Milano nel roster della nuova generazione Tenax. Si avvicina alla musica ambient e downtempo per accompagnare i suoi momenti di pittura, ma ben presto questa passione trascende lo studio-garage per trovare risonanza in realtà locali ed internazionali come il Pikes di Ibiza e l’Hoppetosse di Berlino. 

  Le sue sonorità ibride sfuggono ad una classificazione precisa in un unico genere o stile, ma sono caratterizzate da sonorità che spaziano dal trip hop all’electro, passando per wave e dub. 
  
  I suoi set sono spesso riconoscibili per un continuo crescendo, manifestato in warm-up capaci di avvolgere l’ascoltatore con dolcezza, per poi trasformarsi in esperienze più intense, guidando l’udito verso dimensioni spesso inesplorate.`,
  instagram: "https://www.instagram.com/dilezanghe/",
  soundcloud: "https://on.soundcloud.com/HLvVqFSSPNcx2vWP6/",
  videoMeetDj: ["jRZ_S7b5Rbo"],
});
djDetails.set(Artists_Enum.Gloria, {
  name: "@gloria fregonese",
  img: "/img/djpics/GLORIA.webp",
  description: `Sono Gloria Fregonese, modella e dj veneta. Fin da piccola ho sempre sentito che la musica faceva parte di me, infatti cantavo e ho praticato danza e ginnastica artistica per molti anni. Dai 17 anni ho iniziato a fare la modella e qualche anno fa ho unito le mie due grandi passioni insieme: musica e moda. Vorrei far parte di questo progetto perché penso sia il format giusto per dare vita ai miei sogni, che sono già realtà, ma con questo contest potrebbe diventare ancora più grandi. Io vivo d’arte. 

  Vivo di questo!`,
  instagram: "https://www.instagram.com/gloriafregonese/",
  soundcloud: "https://on.soundcloud.com/J3A8FgsSfnSDqycf9",
  videoMeetDj: ["Os--5qkpEoA"],
});
djDetails.set(Artists_Enum.Ika, {
  name: "@ika faccioli",
  img: "/img/djpics/IKA.webp",
  description: `Le braccia disegnate dai vintage tattoo, lunghi capelli neri e tanta passione per la musica.  Federica Faccioli, è una talentuosa DJ della scena veronese.  Inizia a suonare nella primavera del 2017 per alcuni party in città, facendosi subito notare per il suo stile eclettico e raffinato.  Da settembre 2017 diventa dj resident al Berfi’s club di Verona, dove suonerà per tutta la stagione nella famosa privè room, dedicata ai suoni di tendenza, chiamata “Aquario”.  Nel 2018 diventa Ika Faccioli e inizia un percorso come producer, realizzando 2 singoli: VOSTOK 6.1 e IDEAS FROM MARS (Fams Label).  A giugno 2018 arriva la prima grande occasione di suonare insieme ad alcuni grandi nomi del dj clubbing internazionale.  Le viene infatti proposto di suonare al ROOKAFORTE FESTIVAL 2018 e Ika prende questa occasione al volo, aprendo la serata Richie Hawtin - CLOSE - ricevendo i complimenti dagli altri artisti che suoneranno dopo di lei: Ben Klock, Daniel Avery , Richie Hawtin e Joseph Capriati. Poche settimane più tardi, sempre a Verona , apre con un suo dj set il concerto del “King of Trap” SFERA EBBASTA.  Il 28 giugno 2019 sale su un altro palco importante, quello dei SUBSONICA, al ROOKAZOO MUSIC FESTIVAL, aprendo con un dj set recensito dal critico musicale del quotidiano veronese “L’Arena” Giulio Brusati, che lo ha definito perfetto per introdurre una band come i Subsonica.  Con l’estate 2019 matura la decisione di cambiare genere, abbandona i suoni di tendenza e approda nel mondo dance, a lei più congeniale, sempre dando ai suoi dj set un’impronta inconfondibile per stile e selezione. 
 
  Da ottobre 2019 è dj resident al DISCO LOVE, il nuovo locale di riferimento di Verona, dove diventa la regina della Main Room, dividendo la consolle con i grandi nomi della scena dance italiana e internazionale come Cristian Marchi, Mark & Kremont, Djs From Mars, Papete Winter Tour e prossimamente anche con Benny Benassi.  Negli ultimi approda nella spiaggia di Gallipoli per tutta l’ estate nella famosa discoteca Praja dove farà da resident ogni domenica.  Suonerà poi al Tropicana a Mykonos, in Croazia e in altre tante località.`,

  instagram: "https://www.instagram.com/ika_facciolidj/",
  soundcloud: "https://soundcloud.com/ika-faccioli",
  videoMeetDj: ["zWNXilFwO4E"],
});

djDetails.set(Artists_Enum.Nikita, {
  name: "@nikita voguel",
  img: "/img/djpics/NIKITA.webp",
  description:
    "Nikita Voguel, una DJ/producer italo-indiana, si distingue per la sua impronta musicale esotica e per il suo approccio dinamico, creando una profonda connessione tra la sua anima e il pubblico. Ispirata da influenze EBM ed electro, ora domina ritmi techno groovy, mescolando ad essi melodie orientali ipnotiche.",
  instagram: "https://www.instagram.com/nikita_voguel/",
  soundcloud: "https://soundcloud.com/kyky-gyn-priva",
  videoMeetDj: ["G3O1JP7Grmk"],
});
